import mainMap from './map-bw.jpg'
import equinoxMap from './map-equinox.jpg'

const maps = {
    main: {
        name: 'Georgetown',
        map: mainMap,
        width: 350,
        buffer: 0.000500,
        haloPadding: 40,
        coords: {
            north: 47.560588,
            south: 47.527216,
            east: -122.298516,
            west: -122.345233
        }
    },
    details: [
        {
            name: 'Equinox',
            map: equinoxMap,
            width: 350,
            buffer: 0.000500,
            haloPadding: 0.00010,
            coords: {
                north: 47.545194,
                south: 47.543912,
                east: -122.328048,
                west: -122.328676,
            }
        }
    ]
}

export default maps