import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material';

import { useAuth } from '../auth/contexts/AuthContext'
import { getCaseName } from '../reducers/caseSlice'
import { setDebugActive, getDebugActive } from '../reducers/debugSlice'
import { DashboardBanner } from '../Dashboard'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            position: 'absolute',
            zIndex: 50,
            width: '100vw',
            display: 'flex',
            flexDirection: 'row-reverse',
            right: 0,
            '& a': {
                display: 'block'
            }
        },
        items: {
            display: 'flex',
            flexDirection: 'revert',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingLeft: '2vw',
            paddingRight: '5vw',
            marginBottom: '1vw',
        },
        menuButton: {
            backgroundColor: 'rgb(0 0 0 / .8) !important',
            margin: '1vw',
        }
    }
})

const defaultMenuItems = <>
        {/* <Link to="/">Home</Link>
        <Link to="/about">About</Link> */}
    </>

const Debug = () => {
    const active = useSelector(getDebugActive)
    const dispatch = useDispatch()
    const toggleDebug = (active) => {
        dispatch(setDebugActive(!active))
    }
    return (
        <a onClick={() => toggleDebug(active)}>Debug: { active ? 'On' : 'Off'}</a>
    )
}

const Menu = () => {
    const { classes } = useStyles()
    const { currentUser, userHasCase } = useAuth()
    const history = useHistory()
    const debugActive = useSelector(getDebugActive)
    const caseName = useSelector(getCaseName)
    const [ items, setItems ] = useState(defaultMenuItems)
    const [ showMenu, setShowMenu ] = useState(false)

    useEffect(() => {
        if (currentUser && caseName) {
            setItems(
                <>
                    {currentUser.displayName}
                    {defaultMenuItems}
                    <Link to="/dashboard">Map</Link>
                    <Link to="/profile">Profile</Link>
                    { debugActive ? <Link to="/update-profile">UpdateProfile</Link> : <></> }
                    <Link to="/script">Script</Link>
                    <Debug />
                </>
            )
        } else {
            setItems(
                <>
                    {defaultMenuItems}
                    {/* Alpha Hide: */}
                    {/* Alpha Delete: */}
                    {/* <Link to="/signup" className='alpha-delete'>Sign Up</Link>
                    <Link to="/login" className='alpha-delete'>Login</Link>
                    <Link to="/forgot-password" className='alpha-delete'>ForgotPassword</Link> */}
                </>
            )
        }
    }, [currentUser, caseName, debugActive])

    const toggleMenu = () => {
        const hideMenu = () => {
            setShowMenu(false)
            unlisten()
        }
        const unlisten = history.listen((location, action) => {
            hideMenu()
        })
        setShowMenu(!showMenu)
    }

    if (!currentUser || !caseName || caseName === '') {
        return <></>
    }

    const rootShowMenu = {
        backgroundColor: showMenu ? 'rgb(0 0 0/.8)' : 'transparent',
    }

    return (
        <div id="menu-button-container" className={clsx('component', classes.root)} style={rootShowMenu}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Button 
                variant="contained" 
                onClick={() => toggleMenu()}
                className={classes.menuButton}
                sx={{ width: 5,
                    padding: 0,
                    minWidth: 30,
                    border: 'solid #fff 1px',
                }}>=</Button>
            </div>
            
            <div className={classes.items} style={{ display: showMenu ? 'flex' : 'none' }}>
                <DashboardBanner />
                {items}
            </div>
        </div>
    )
}

export default Menu
