import React, { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'

import cases from '../../data/cases'
import { caseType } from '../types/case'
import Content from '../Content'
import settings from '../../settings.json'

import { useAuth } from '../auth/contexts/AuthContext'
import { resetMyLocation } from '../reducers/myLocationSlice'
import { resetPOI } from '../reducers/poiSlice'
import { resetCase, setCase } from '../reducers/caseSlice'
import { resetScene } from '../reducers/sceneSlice'
import { updateUserActiveCase, getUserActiveCase, addItemToPath, createUser } from '../../utils'
import { ButtonNoir } from '../library'

const CaseIntro = ( props ) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { currentUser, resetCache, createAnonymousUser, resetCaseSettings } = useAuth()
    const paramCaseName = props.match.params.id || ''
    const [ casePreview, setcasePreview ] = useState()
    const [ activeCase, setActiveCase ] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    useEffect(() => {
        const caseKey = Object.keys(cases).find( caseName => caseName === paramCaseName)     
        if (caseKey) {
            if (settings.debug) {
                console.log('previewCase Found',cases[caseKey]);
            }
            setcasePreview(cases[caseKey])
        }
    }, [])

    const setUserCase = async () => {
        // TODO: update the user case
        const caseReset = await resetCaseSettings({ resetLogout: false, caseId: casePreview.id })
        if (caseReset) {
            await addItemToPath(`chapter-1`)
            history.push(`/dashboard`)
        }
    }

    const startCase = async () => {
        setLoading(true)
        dispatch(setCase(casePreview.id))
        // is user logged in?
        if (currentUser) {
            const userActiveCase = await getUserActiveCase()
            if (userActiveCase && userActiveCase.id === casePreview.id) {
                const response = window.confirm(`You are already working this case. If you restart, you will lose all progress.  Are you sure you wish to restart ${casePreview.name}?`)
                if (response) {
                    setUserCase()
                }
                setLoading(false)
            } else if (userActiveCase?.id) {
                const response = window.confirm(`Are you sure you wish to start the case ${casePreview.name}? You will lose all progress with case ${userActiveCase.id}.`)
                if (response) {
                    setUserCase()
                }
                setLoading(false)
            } else {
                setUserCase()
            }
        } else {
            if (!settings.forceAnonymous) {
                history.push(`/login?case=${casePreview.id}`)
            } else {
                try {
                    setError("")
                    setLoading(true)
                    const response = await createAnonymousUser()
                    setLoading(false)
                    // add user to firestore and redirect to home
                    if(!createUser(response.user.uid, casePreview.id)) {
                        throw 'User not created in firestore'
                    }
                    history.push("/")
                } catch {
                    setLoading(false)
                    setError("Failed to create anonymous user")
                }
            }
        }
    }

    if (!casePreview) {
        return (
            <div>
                <h3>{paramCaseName}</h3>
                Invalid Case. Go Hunting.
            </div>
        )
    }

    const { name, intro, hideName } = casePreview

    return (
        <>
            {hideName ? null : <h1>{name}</h1> }
            {intro.content ? <Content data={intro.content} /> : null}
            <ButtonNoir onClick={() => startCase()} loading={loading}>Take the Case</ButtonNoir>
        </>
    )
}

export default CaseIntro