import { createSlice } from '@reduxjs/toolkit'

import { getCaseSubFolderFile  } from '../../utils'

// import caseMaps from '../../data/cases/001-the-mollusck/maps'
// import mainMap from '../../data/cases/001-the-mollusck/maps/map-bw.jpg'
// import mainMapPlaceholder from '../../data/cases/001-the-mollusck/maps/map-bw-placeholder.jpg'

interface stateModal {
    source: 'geolocation' | 'click',
    loading: boolean,
    long: string | null,
    lat: string | null,
    map?: {} | null,
}

const initialState: stateModal = { 
    source: 'click',
    loading: false,
    long: null,
    lat: null,
    //map: caseMaps.main // rely on dashboard to load init map from caseFile
}

// const initialState: any = async () => {
//     // const caseMapsFile = await getCaseSubFolderFile('maps')
//     const vals: stateModal = { 
//         source: 'click',
//         loading: false,
//         long: null,
//         lat: null,
//         map: capitolHillMap // caseMapsFile
//     }
//     return vals
// }

export const myLocationSlice = createSlice({
    name: 'myLocation',
    initialState,
    reducers: {
        resetMyLocation: () => {
            return initialState
        },
        setLoading: (state, action) => {
            if (action.payload && typeof action.payload === 'boolean') {
                state.loading = action.payload
            } else {
                state.loading = !state.loading
            }
        },
        setMyLocation: (state, action) => {
            if (action.payload) {
                state.lat = action.payload.lat
                state.long = action.payload.long
                state.loading = false
            } else {
                state = initialState
            }
        },
        setSource: (state, action) => {
            if (!action.payload || action.payload === '') {
                state.source = state.source === 'geolocation' ? 'click' : 'geolocation'
            } else {
                state.source = action.payload    
            }
        },
        setMap: (state, action) => {
            if (action.payload) {
                state.map = action.payload
            } else if (action.payload === '') {
                state.map = ''
            } else {
                console.error('myLocationSlice setMap() issues. debug..');
                // this used to be set to 'Capitol Hill' as a fallback. See if this breaks in future.
                state.map = ''
            }
        }
    }
})

export const { resetMyLocation, setMyLocation, setSource, setLoading, setMap } = myLocationSlice.actions

export const getMyLocation = (state: { myLocation: any; }) => state.myLocation 
export const getSource = (state: { myLocation: any }) => state.myLocation.source
export const getLoading = (state: { myLocation: any }) => state.myLocation.loading
export const getMap = (state: { myLocation: any }) => state.myLocation.map

export default myLocationSlice.reducer