import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom';

import { getCaseName } from '../reducers/caseSlice'
import { getScene } from '../reducers/sceneSlice'
import { sceneType, sceneArrayType, sceneStatusType } from '../types/scene'
import { itemType } from '../types/item'
import { puzzleType } from '../types/puzzle'
import SceneButton from './SceneButton'
import { MediaShow } from '../Media'
import Content from '../Content'
import Breadcrumbs from '../Breadcrumbs'
import { getSceneMissingItemReqs, getSceneMissingPuzzleReqs, getItemByIdWithCase, setSceneStatus, getCaseDataFile, addDashScenesToQueue, pathContainsItem } from '../../utils'
import { ProgressNoir } from '../library'
import Inspector from '../Inspector';

export const sceneButtonStyles = {
    display: 'block',
    width: '100%',
    padding: '8px',
    margin: '5px 0'
}

export const sceneUnvisitedButtonStyles = {
    ...sceneButtonStyles,
    backgroundColor: '#793737'
}

const Scene = () => {
    const scene = useSelector(getScene)
    const { name, scenes, content, media, inspector } = scene || {}
    const [caseData, setCaseData] = useState<any>({})
    const [ customComponent, setCustomComponent ] = useState<JSX.Element>()
    const [ sceneButtons, setSceneButtons ] = useState<JSX.Element[] | null>()
    const [ missingItemReqs, setMissingItemReqs ] = useState<itemType[]>()
    const [ missingPuzzleReqs, setMissingPuzzleReqs ] = useState<puzzleType[]>()

    const caseName = useSelector(getCaseName)
    const customCompPath = scene && scene.component ? `custom/${caseName}/${scene.component}` : ''

    const fetchMissingReqs = async () => {
        // get missing item reqs
        const itemReqs: any = await getSceneMissingItemReqs(scene)
        // get missing puzzle reqs
        const puzzleReqs: puzzleType[] | any = await getSceneMissingPuzzleReqs(scene)
        setMissingItemReqs(itemReqs)
        setMissingPuzzleReqs(puzzleReqs)
    }

    const updateSceneStatus = async (id: string, status: sceneStatusType) => {
        return await setSceneStatus(id, status)
    }

    useEffect(() => {
        getSettings()
    }, [])

    useEffect(() => {
        fetchMissingReqs()
        // build scene buttons, if any exist
        if (scenes) {
            buildSceneButtons()
        } else {
            setSceneButtons(null)
        }
        // if POI contains a special component, load it
        if (customCompPath) {
            const Component = React.lazy(() => import(`../${customCompPath}`))
            setCustomComponent(<Component />)
        }
        // check for dashScenes
        if (scene?.dashScenes) {
            addDashScenesToQueue(scene.dashScenes)
        }
    }, [scene])

    const getSettings = async () => {
        // get settings, case data & case maps
        const caseDataFile = await getCaseDataFile()
        setCaseData(caseDataFile.default)
    }

    const buildSceneButtons = async () => {
        // build scenes (if there are any)
        const parentScene = scene
        const scenesMeta: sceneType[] = await Promise.all(scenes.map(async (scene: sceneType) => {
            return {
                ...scene,
                visited: await pathContainsItem(scene.id)
            }
        }))
        const sceneData = scenesMeta.map((scene: sceneType) => 
            <SceneButton 
                key={scene.id} 
                scene={scene} 
                parentScene={parentScene} 
                style={scene.visited ? sceneButtonStyles : sceneUnvisitedButtonStyles}
            />
        )
        setSceneButtons(sceneData)
    }

    // send user to dashboard if no POI in redux
    if (!scene) {
        return (<Redirect to='/dashboard' />)
    }

    /* CONTAINER
     * for all rendered output
    */
    const Container = (state: any) => {
        return (
            <div className="root">
                <div className="component">
                    {state.children}
                </div>
            </div>
        )
    }
    
    /* PUZZLE
     * show puzzle, if they are missing puzzle requirements
    */
    if (missingPuzzleReqs && missingPuzzleReqs.length >= 1) {
        // only show one puzzle at a time
        const puzzleCompPath = `custom/${caseName}/${missingPuzzleReqs[0].component}`
        if (puzzleCompPath.includes('//')) {
            console.error('Error in puzzleCompPath:', puzzleCompPath);
            return (
                <Container>
                    Error. Please reset the game.
                </Container>
            )
        }
        const PuzzleComponent = React.lazy(() => import(`../${puzzleCompPath}`))
        const missingPuzzleReqMedia = missingPuzzleReqs[0].media
        return (
            <Container>
                { missingPuzzleReqMedia ? <MediaShow content={missingPuzzleReqMedia} /> : null }
                <Suspense fallback={<ProgressNoir color="secondary" />}>
                    <PuzzleComponent />
                </Suspense>
            </Container>
        )
    }

    /* MISSING ITEMS
     * show user message, if they are missing ITEM requirements
    */
    if (missingItemReqs && missingItemReqs.length >= 1) {
        const missingItemReqMedia = missingItemReqs[0].media
        return(
            <Container>
                { missingItemReqMedia ? <MediaShow content={missingItemReqMedia} /> : null }
                <h2><Breadcrumbs />{name}</h2>
                Item(s) needed to unlock: 
                {missingItemReqs.map((item: any) => {
                    const caseItem = getItemByIdWithCase(item.id, caseData)
                    if (item.content) {
                        return (<div key="item.id">
                            <Content data={item.content} />
                            {caseItem.name}
                        </div>)
                    } else {
                        return (<div key={item.id}>{caseItem.name}</div>)
                    }
                })}
            </Container>
        )
    }
    
    // if no missing requirments or items, mark the scene as visited
    if ((missingItemReqs && missingItemReqs.length < 1) 
        && (missingPuzzleReqs && missingPuzzleReqs.length < 1)) {
        updateSceneStatus(scene.id, 'visited')
    }
    
    /* LOADING
     * show loading message until reqs come thru
    */
    if (!missingItemReqs || !missingPuzzleReqs) {
        return(<div>Loading reqs...</div>)
    }   

    /* BASIC SCENE
     * show basic scene
    */
   
    return(
        <Container>
            <Suspense fallback={<ProgressNoir color="secondary" />}>
                { inspector ? <Inspector 
                    src={`${inspector.path}/${inspector.file}`} 
                    placeholderSrc={ inspector.placeholder ? `${inspector.path}/${inspector.placeholder}` : undefined }
                /> : null }
                { media ? <MediaShow content={media} /> : null }
                { content ? <Content data={content} /> : null }
                { customComponent }
            </Suspense>
            {sceneButtons}
        </Container>
    )
}

export default Scene