import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import App from './App'
import themeConfig from '../theme'

// MUI TSS REF / v4->v5: 
// https://mui.com/material-ui/migration/migrating-from-jss/#2-use-tss-react
// https://mui.com/material-ui/migration/v5-style-changes/
// https://mui.com/material-ui/migration/migration-v4/
// may need to update TS module: https://mui.com/material-ui/customization/theming/
const theme = createTheme(themeConfig)

// might need to add: 
// <ConnectedRouter history={history}>
// <Provider store={store}>
// see equinox Root for example

export default function Root({ store, history }) {
  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>
  )
}
