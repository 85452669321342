import { db } from '../firebase'
import userType, { fsCaseType } from '../components/types/user'
import settings from '../settings.json'

interface updateUserType {
    uid: string,
    userUpdates: {}
}

/*
 * CREATE functions
*/

const createUser = (uid: string, caseId?: string) => {
    // note: empty arrays dont actually get created. Just here for reference
    const userCaseId = caseId || settings.default.case
    const newUser: userType = {
        uid,
        cases: [],
        activeCase: {
            id: userCaseId,
            chapter: 1,
            puzzles: [],
            poi: [],
            scenes: [],
            items: [],
            path: []
        }
    }
    return db.collection('users').add(newUser)
        .then((docRef) => {
            console.log('User created with ID: ', docRef.id, ', for case: ', userCaseId);
            return true
        })
        .catch((error) => {
            console.error('Error adding document: ', error);
            return false
        })
}

/*
 * RESET functions
*/

const resetUserCase = async (caseId: string) => {
    return await updateUserActiveCase({
        id: caseId,
        chapter: 1,
        puzzles: [],
        poi: [],
        scenes: [],
        items: [],
        path: []
    })
}

/*
 * GET functions
*/

const getSessionUID = () => {
    const uid = sessionStorage.getItem(`uid`)
    return uid
}

const getUserDocID = async (uid?: string) => {
    const sessionUID = sessionStorage.getItem(`uid`)
    const targetUID = uid ? uid : sessionUID

    return await db.collection('users')
        .where('uid', '==', targetUID)
        .get()
        .then((querySnapshot) => {
            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].id
            } else {
                if (settings.debug) {
                    console.log(`NO BIG DEAL: No fs user returned for targetUID: ${targetUID}`)
                }
                // TODO: create an alert that asks is users wants to add user data to db
            }
        })
        .catch((error) => {
            console.log('Error getting user: ', uid);
            console.log(error);
            return null
        })
}

const getUser = async (uid?: string) => {
    const sessionUID = sessionStorage.getItem(`uid`)
    const targetUID = uid ? uid : sessionUID
    if (!targetUID) {
        return null
    }
    return await db.collection('users')
        .where('uid', '==', targetUID)
        .get()
        .then((querySnapshot) => {
            if (!querySnapshot.empty) {
                const user = querySnapshot.docs[0].data()
                if (settings.debug) {
                    console.log('GET user: ',user);
                }
                return user
            }
        })
        .catch((error) => {
            alert('User does not exist in firestore')
            console.log('Error getting user: ', uid)
            console.log(error)
            return null
        })
}

const getUserActiveCase = async (uid?: string) => {
    // sessionStorage.setItem('activeCase', JSON.stringify(user.activeCase))
    const activeCaseSessionJSON = sessionStorage.getItem('activeCase')
    const activeCaseSession = activeCaseSessionJSON ? JSON.parse(activeCaseSessionJSON) : null
    if (settings.debug) {
        console.log('getUserActiveCase() active case found in session storage:', activeCaseSession);
    }
    
    if (activeCaseSession?.id) {
        // if active case exists in session storage, send that.
        return activeCaseSession
    } else {
        if (settings.debug) {
            console.log('getUserActiveCase (user) not found in session storage. get user from firebase');
        }
        // if not, query firebase and update session storage
        const user = await getUser(uid)
        // update the active case in local storage
        if (user) {
            updateActiveCaseSessionStorage(user.activeCase)
            return user.activeCase
        } else {
            return null
        }
    }
}

/*
 * UPDATE functions
*/

const updateUser = async (userUpdates: {}) => {
    console.log('TODO: DOES THIS EVER GET CALLED???');
    // get the user doc id
    const docID = await getUserDocID()
    // get the user obj
    const user = await getUser()
    const updatedUser = {
        ...user,
        ...userUpdates
    }
    console.log('POST update user');
    
    if (!user || !docID) {
        return false
    }

    // update the active case in local storage
    updateActiveCaseSessionStorage(user.activeCase)
    
    // update the doc on firestore
    return await db.collection('users')
        .doc(docID)
        .update(updatedUser)
        .then(() => {
            if (settings.debug) {
                console.log('User updated on firestore:',userUpdates);
            }
        })
        .catch((error) => {
            console.error(`Error updating document: ${docID}`, error);
        });
}

const updateUserActiveCase = async (updates: fsCaseType) => {
    const { id, items, puzzles, poi, scenes, path, chapter, dashScenesQueue } = updates

    const activeCase = await getUserActiveCase()
    const activeCaseUpdates = {
        ...activeCase,
        ...updates 
    }

    updateActiveCaseSessionStorage(activeCaseUpdates)

    if (settings.debug) {
        console.log('updateUserActiveCase() update active case in session storage and firebase:', activeCaseUpdates);
    }
    
    // get the user doc id
    const docID = await getUserDocID()
    if (!docID) {
        throw('Error loading user')
    }
    
    return await db.collection('users')
        .doc(docID)
        .update({
            activeCase: activeCaseUpdates
        })
        .then(() => {
            if (settings.debug) {
                console.log('User updated:',activeCaseUpdates);
            }
        })
        .catch((error) => {
            console.error(`Error updating document: ${docID}`, error);
        });
}

const updateActiveCaseSessionStorage = (activeCase: {}) => {
    // update the active case in local storage
    sessionStorage.setItem('activeCase', JSON.stringify(activeCase))
}

export {
    getSessionUID,
    createUser,
    getUser,
    getUserActiveCase,
    updateUser,
    updateUserActiveCase,
    resetUserCase
}