import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"

import { useAuth } from '../auth/contexts/AuthContext'
import MyLocation from '../MyLocation'
import Map from '../Map'
import DashScene from '../DashScene'
import Code from '../Code'
import { DashboardBanner } from '.'
import { getCaseDataFile } from '../../utils'
import settings from '../../settings.json'

const Dashboard = () => {
    const { userHasCase } = useAuth()
    const history = useHistory()
    const [ loading, setLoading ] = useState(true)
    const [ caseDataFile, setCaseDataFile ] = useState({})

    useEffect(() => {
        checkIfUserHasCase()
        getSettings()
    }, [])

    const checkIfUserHasCase = async () => {
        // if user doesnt have case, send them home
        const userCase = await userHasCase()
        if (!userCase) {
            history.push(`/welcome`)
        } else {
            setLoading(false)
        }
    }

    const getSettings = async () => {
        // get settings, case data & case maps
        const caseDataFile = await getCaseDataFile()
        setCaseDataFile(caseDataFile.default)
    }

    if (loading) {
        return (<>Loading Dashboard....</>)
    }

    // TODO: is there a way to pass csaeData to Map to reduce calls?
    // as in: <Map caseDataFile={caseDataFile} />
    
    return (
        <>
            <Map />
            <Code />
            <DashScene caseDataFile={caseDataFile} />
            <MyLocation />
        </>
    )
}

export default Dashboard