import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setMyLocation, setSource, setLoading, getMyLocation } from '../reducers/myLocationSlice'
import { getDebugActive } from '../reducers/debugSlice'

type coordsType = {
    lat: number,
    long: number,
}

type coordsStringType = {
    lat: string,
    long: string,
}

function precise(x: string) {
    return Number.parseFloat(x).toFixed(5);
}

const MyLocation = () => {
    const dispatch = useDispatch()
    const myLocation = useSelector(getMyLocation)
    const debugActive = useSelector(getDebugActive)
    const [ lastChecked, setLastChecked ] = useState('')
    const [ usingTimer, setUsingTimer ] = useState(false)
    const [ debugMyLoc, setDebugMyLoc ] = useState<coordsStringType | undefined>() // for debugging purposes only
    const [ offsetLat, setOffsetLat ] = useState<string>('')
    const [ offsetLong, setOffsetLong ] = useState<string>('')

    const timerInterval = 1000 * 10 // seconds

    useEffect(() => {
        let interval: any
        if (myLocation.source === 'geolocation') {
            if (!usingTimer) {
                interval = setInterval(() => {
                    setUsingTimer(true)
                    getLocation()
                }, timerInterval)
            }
        } else {
            setUsingTimer(false)
            clearInterval(interval);
        }
        return () => {
            setUsingTimer(false)
            clearInterval(interval)
        }
    }, [myLocation.source]);

    const updateMyLoc = (myLoc?: coordsStringType) => {
        dispatch(setLoading(true))
        dispatch(setMyLocation(myLoc))
        setDebugMyLoc(myLoc) 
    }

    const changeSource = () => {
        dispatch(setSource(''))
    }

    const offsetCoords = (coords: coordsType) => {
        const lat = offsetLat && !!parseFloat(offsetLat) && parseFloat(offsetLat) !== 0 ? coords.lat + parseFloat(offsetLat) : coords.lat
        const long = offsetLong && !!parseFloat(offsetLong) && parseFloat(offsetLong) !== 0 ? coords.long + parseFloat(offsetLong) : coords.long
        return {
            lat,
            long
        }
    }

    function getLocation() {
        dispatch(setLoading(true))
        updateMyLoc()

        const options = {
            enableHighAccuracy: true,
            timeout: timerInterval,
            maximumAge: 0
        }

        try {
            navigator.geolocation.getCurrentPosition(
                (GeolocationPosition) => {
                    const now = new Date()
                    setLastChecked(now.toString())
                    // console.log('Geo Loc Success: ',GeolocationPosition)
                    // setGeoLocation(GeolocationPosition) do i need this?
                    const coords = offsetCoords({ 
                        lat: GeolocationPosition.coords.latitude, 
                        long: GeolocationPosition.coords.longitude 
                    })
                    updateMyLoc({
                        lat: precise(coords.lat.toString()),
                        long: precise(coords.long.toString())
                    })
                },
                (GeolocationPositionError) => {
                    console.log(`Geo Loc Error`,GeolocationPositionError)
                    dispatch(setLoading(false))
                },
                options
            )
        } catch (error) {
            console.log(`Get Location Error ${error}`)
        }
    }

    if (!debugActive) {
        return <></>
    }

    return(
        <div className="component">
            My Location<br/>
            { myLocation.source === 'geolocation' ? `Last updated: ${lastChecked}` : null }<br/>
            {  `LAT: ${debugMyLoc?.lat} LONG: ${debugMyLoc?.long}`  }
            <button onClick={() => getLocation()} disabled={myLocation?.source === 'click'}>
                get my geo location<br/>
                Loading: {myLocation?.loading ? myLocation.loading.toString() :  'undefined'}
            </button>
            <button onClick={() => changeSource()}>
                Change Source: {myLocation.source}
            </button>
            Offset LAT: <input value={offsetLat} onChange={(e) => setOffsetLat(e.target.value)}></input>
            Offset LONG: <input value={offsetLong} onChange={(e) => setOffsetLong(e.target.value)}></input>
        </div>
    )
}

export default MyLocation