import { poiType, poiArrayType } from '../components/types/poi'
import { caseDataType } from '../components/types/case'
import { getCaseData, getUserActiveCase, updateUserActiveCase, getScenesWithStatus, getChapter, addItemToPath, getPath, getAllPOIScenes } from '../utils'
import { fsPOI } from '../components/types/user'
import settings from '../settings.json'

interface setUserPOIStatusType {
    id: string, 
    status?: 'visited' | 'complete' | 'not-visited',
    foreignId?: string,
    foreignStatus?: null | 'visited' | 'complete' | 'not-visited',
}

/*
 * Type Guard
*/
export const isPOI = (arg: any): arg is poiType => {
    // if (!arg || !arg.id) { return }
    if (
        (!arg.id || typeof(arg.id) !== 'string')
        || (!arg.type || typeof(arg.type) !== 'string')
        || (!arg.name || typeof(arg.name) !== 'string')
        || (!arg.location || typeof(arg.location) !== 'string')
        || (!arg.coordinates || typeof(arg.coordinates) !== 'object')
        || (!arg.content || !Array.isArray(arg.content))
        // || (!arg.scenes || !Array.isArray(arg.scenes)) OPTIONAL
        ) {
            // TODO: check for sub types: content, coords, scenes
        return false
    }
    return true
}

/*
 * Middle Men
 * Data getters/setters
*/
export const getUserPOIWithStatus = async (status: 'visited' | 'complete' | 'not-visited' = 'visited') => {
    // NOTE: getUserPOIWithStatus and getScenesWithStatus used to be similar. now getScenesWithStatus has addl params. might need to upgrade getUserPOIWithStatus in future
    const activeCase = await getUserActiveCase()
    if (!activeCase) {
        return false
    }
    const poi: string[] = activeCase.poi ? activeCase.poi : []
    const poiWithStatus = poi.filter( (poi: any) => poi.status === status)

    return poiWithStatus
}

export const setUserPOIStatus = async ({ id, status = 'visited', foreignId, foreignStatus = 'visited' }: setUserPOIStatusType) => {
    // Save the POI as an object to storage
    const activeCase = await getUserActiveCase()
    if (!activeCase) {
        return false
    }
    const poi: fsPOI[] = activeCase.poi ? activeCase.poi : []
    let matchingPOI
    // update foreign POI (if passed)
    if (foreignId) {
        matchingPOI = poi.find(item => item.id === id)
        if (!matchingPOI) {
            // create new poi item
            poi.push({
                id: foreignId,
                status: foreignStatus ? foreignStatus : 'visited'
            })
            await updateUserActiveCase({dirty: true, poi})    
        }
    }
    // update POI
    matchingPOI = poi.find(item => item.id === id)
    if (!matchingPOI) {
        // create new poi item
        poi.push({
            id,
            status
        })
        return await updateUserActiveCase({dirty: true, poi})
    } else {
        // TODO: update existing? maybe not, because its too draining on firebase
        return null
    }
}

export const getPOIReqComplete = async (poiUnfiltered?: poiArrayType) => {
    let poi:poiArrayType = []
    if (poiUnfiltered) {
        poi = poiUnfiltered
    } else {
        poi = await getAllChapterPOI({})
    }

    // return only poi that have all requirments..
    const visitedPOI: any = await getUserPOIWithStatus()

    // return only visited scenes
    const visitedScenes: any = await getScenesWithStatus({status: 'visited'})

    if (!visitedPOI && !visitedScenes) {
        // if there are no visited POI or visited scenes, and POI has req, then ReqComplete must be false
        return []
    }

    poi = poi.filter( (poi: any) => {
        if (poi && poi.requirements) {
            // check status=visited reqs
            // get POI visited and scenes visited
            const poiVisited = visitedPOI.map( (req: any) => req.id)
            const scenesVisited = visitedScenes.map( (req: any) => req.id)
            const reqsVisited = [...poiVisited, ...scenesVisited]
            // check if missing reqs
            const missingReqPOI = poi.requirements
                .map( (p: any) => p.id)
                .filter( (p: any) => !reqsVisited.includes(p))

            if (!missingReqPOI || missingReqPOI.length <= 0) {
                return true
            }
        } else {
            // ..or have no requirements
            return poi
        }
    })

    return poi
}

/*
 * Case Scene getters
*/
export const getPOIById = async (id: string) => {
    // TODO: this only handles POI, not scenes. Create an import for Scenes if needed
    // old method:
    // const allPOI = await getAllChapterPOI({})
    // return allPOI.find(poi => poi.id === id)
    const caseData = await getCaseData()
    const allPOI = caseData.chapters.flat().map((chapter: { poi: any }) => chapter.poi)
    return allPOI.flat().find((poi: { id: string }) => poi.id === id)
}

export const getPOIByIdWithCase = (id: string, caseData: caseDataType) => {
    // TODO: this only handles POI, not scenes. Create an import for Scenes if needed
    const allPOI = caseData.chapters.flat().map((chapter: { poi: any }) => chapter.poi)
    return allPOI.flat().find((poi: { id: string }) => poi.id === id)
}

export const getAllChapterPOI = async ({ targetChapter, includePrevious = true, flattened = true, mergeImports }: 
    { targetChapter?: number, includePrevious?: boolean, flattened?: boolean, mergeImports?: boolean }) => {
    // get all poi up to current chapter
    let poi:poiArrayType 
    let importedPOI: string[] = []
    const caseData: caseDataType | any = await getCaseData()
    
    poi = caseData.chapters
        .filter((ch: { chapter: number }) => {  
            if (includePrevious) {
                return targetChapter ? ch.chapter <= targetChapter : ch.chapter    
            } else {
                return targetChapter ? ch.chapter === targetChapter : []
            }
        })
        .map((chapter: { poi: any }) => chapter.poi)

    if (mergeImports) {
        poi = poi.map((chPOI: any) => {
            // merge imports
            return chPOI.map((poi: any) => {
                if (poi.import) {
                    // POI contains imports
                    importedPOI.push(poi.import)                    
                    const importPOI: any = getPOIByIdWithCase(poi.import, caseData)
                    const importPOIScenes = importPOI && importPOI.scenes ? importPOI.scenes : []
                    const poiScenes = poi && poi.scenes ? poi.scenes : []
                    return {
                        ...poi,
                        location: importPOI?.location,
                        coordinates: importPOI?.coordinates,
                        scenes: [...importPOIScenes, ...poiScenes]
                    }
                } else {
                    return poi
                }
            })
        })
    }

    if (flattened) {
        poi = poi.flat()
        // remove any poi that were imported (this can only be done if flattened and will need to be upgraded if !flattened required)
        if (importedPOI.length >= 1) {
            poi = poi.filter(poi => !importedPOI.includes(poi.id))
        }
    }

    return poi
}

export const getPOIChapter = async (poiID: string) => {
    // loop thru all chapters, until parent of poi is discovered
    let chapter
    const caseData: caseDataType | any = await getCaseData()
    for (let c = 1; c <= caseData.chapters.length; c++) {
        const poi = getAllChapterPOI({ targetChapter: c, includePrevious: false })    
        if ((await poi).some(p => p.id === poiID)) {
            chapter = c
            break;
        }
    }
    return chapter ? chapter : undefined
}

export const isPOIVisited = async (id: string) => {
    const activeCase = await getUserActiveCase()
    if (!activeCase) {
        return null
    }
    const poi = activeCase.poi.find((poi: poiType) => poi.id === id)
    return poi || null
}

export const showPOI = async (poi: poiType) => {
    const chapterNumber = await getChapter()
    
    const poiId = poi.global ? `${poi.id}-ch${chapterNumber}` : poi.id
    if (settings.debug) {
        console.log('Update user POI');
        console.log('TODO: figure out a way to make this only a single call/update');
    }
    await setUserPOIStatus({ id: poiId })
    if (poi.foreignId) {
        await setUserPOIStatus({ id: poi.foreignId })
    }
    if (poi.global && poi.globalId) {
        // also set the global visited status
        await setUserPOIStatus({ id: `${poi.globalId}-ch${chapterNumber}` })
    }

    if(!poi.hideFromPath) {
        await addItemToPath(poiId)
    }

    return true
}

export const getPOIByType = async (type: string = 'geo') => {
    return await (await getAllChapterPOI({})).filter(poi => poi.type === type)
}

export const getRemainingReqPOI = async (): Promise<poiArrayType> => {
    let remainingPoi:poiType[] = []
    const chapterNumber = await getChapter()
    const chapterPoi:poiType[] = await getAllChapterPOI({targetChapter: chapterNumber, includePrevious: false})
    const visitedPOI: any = await getUserPOIWithStatus()
    chapterPoi.forEach( async (poi: poiType) => {
        if (poi && poi.required) {
            // check status=visited reqs
            // get POI visited and paths visited
            const poiVisited = visitedPOI.map( (req: any) => req.id)
            const pathsVisited = await getPath()
            const inPOI = poiVisited.includes(poi.id)
            const inPath = pathsVisited.includes(poi.id)
            const notVisited = !inPOI && !inPath
            if (notVisited) {
                remainingPoi.push(poi)
            }
        }
    })
    return remainingPoi
}

export const getUnvisitedPOIAndScenes = async () => {
    const currentChapter = await getChapter()
    const poiVisited = await getUserPOIWithStatus('visited') || []
    const scenesVisited = await getScenesWithStatus({status: 'visited'}) || []
    const caseData: caseDataType | any = await getCaseData()
    const activeChapters = caseData.chapters.filter( (c: any) => c.chapter <= currentChapter)
    const poiAndScenesVisited = [...poiVisited, ...scenesVisited].map( (item: any) => item.id)
    const unvisitedPOIScenes = getAllPOIScenes(activeChapters)
        .filter( (poi: any) => poi.required === true && poi.chapter === false) // remove non-required and chapter nodes
        .map( (poi: any) => poi.id)
        .filter( (poi: any) => !poiAndScenesVisited.includes(poi))
    return unvisitedPOIScenes
}