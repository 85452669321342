import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui';

interface Props {
    path?: string,
    file: string
}

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            maxWidth: '100%'
        },
        video: {
            width: '100%',
            height: 780,
            backgroundColor: 'rgba(255 255 255 / .1)',
            minWidth: '75%',
            minHeight: '75%',
            top: '10%'
        }
    }
})

const MediaVideo = ({ path, file }: Props) => {
    const { classes } = useStyles()
    const video = `${path}/${file}`
    const [videoPath, setvideoPath] = useState<any>()
    
    useEffect(() => {
        import(`../../${video}`).then(video => {
            setvideoPath(video.default)
        }).catch(error => {
            console.log('error:',error);
            console.error(`File not found:`, `../../${video}`);
        })
    }, [video])
    
    return (
        <div className={classes.container}>
            { videoPath
                ? <video autoPlay loop muted className={classes.video}>
                    <source src={videoPath} type="video/mp4"></source>
                </video>
                : null 
            }
        </div>
    )
}

export default MediaVideo