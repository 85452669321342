import main from './main'

import global from './global'

// compile all chapters
import ch01 from './chapters/01'
import ch02 from './chapters/02'

export default {
    ...main,
    ...global,
    chapters: [
        ch01,
        ch02
    ]
}

