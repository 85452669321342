import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { getCaseName } from '../reducers/caseSlice'
import { getPOI } from '../reducers/poiSlice'
import Content from '../Content'
import { SceneButton } from '../Scene'
import { getScenesVisitedReqComplete, addDashScenesToQueue, isItemInPath } from '../../utils'
import { MediaShow } from '../Media'
import { ProgressNoir } from '../library'
import { sceneButtonStyles, sceneUnvisitedButtonStyles} from '../Scene'
import Inspector from '../Inspector';

const useStyles = makeStyles()((theme) => {
    return {
        buttonsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        }
    }
})

const getScenes = (scene) => {
    // // step thru and return all scenes in POI
    // var level = 1;
    // for(var key in object) {
    //     if (isNaN(+key) && key !== 'poi' && key !== 'scenes') {
    //         continue
    //     }
    //     if(typeof object[key] == 'object') {
    //         if (object[key].id) {
    //             allPOIScenes.push({
    //                 id: object[key].id ? object[key].id : 'no id',
    //                 required: object[key].required ? object[key].required : true,
    //                 chapter: object[key].chapter ? true : false // pass this to remove chapter node later in code
    //             })
    //         }
    //         level = Math.max(POIScenes, level);
    //         var POIScenes = getAllPOIScenes(object[key]) + 1;
    //     }
    // }
    // return allPOIScenes;
}

const POI = () => {
    const { classes } = useStyles()
    const poi = useSelector(getPOI)
    const { scenes, media, inspector } = poi || { scenes: [], media: [] }
    const [ poiCustomComponent, setPOICustomComponent ] = useState()
    const [ sceneButtons, setSceneButtons ] = useState()
    const [ altContent, setAltContent ] = useState()
    const [ altMedia, setAltMedia ] = useState()

    const caseName = useSelector(getCaseName)
    const customCompPath = poi && poi.component ? `custom/${caseName}/${poi.component}` : ''

    const handlePOIAltContent = (content) => {
        setAltContent(content)
    }

    const handlePOIAltMedia = (media) => {
        setAltMedia(media)
    }

    useEffect(() => {
        // if POI contains a special component, load it
        if (customCompPath) {
            const Component = React.lazy(() => import(`../${customCompPath}`))
            setPOICustomComponent(
                <Component 
                    onPOIAltMedia={(media) => handlePOIAltMedia(media)} 
                    onPOIAltContent={(content) => handlePOIAltContent(content)}
                />
            )
        } else {
            setPOICustomComponent(null)
        }
        buildSceneButtons()
        // check for dashScenes
        if (poi.dashScenes) {
            addDashScenesToQueue(poi.dashScenes)
        }
    }, [poi])

    const buildSceneButtons = async () => {
        if (!scenes || scenes.length <= 0) { return }
        // get scenes that have requirements complete
        const scenesReady = await getScenesVisitedReqComplete(scenes)
        // build scenes (if there are any)
        const scenesReadyWithVisted = await Promise.all(scenesReady
            .map( async s => {
                return {
                    ...s,
                    visited: await isItemInPath(s.id) ? true : false
                }
            }
        ))
        // scenesReadyWithVisted.sort((a, b) => { return a.visited - b.visited; } )
        const sceneHTML = scenesReadyWithVisted.map(scene => 
            <SceneButton key={scene.id} scene={scene} style={scene.visited ? sceneButtonStyles : sceneUnvisitedButtonStyles}/>)
        setSceneButtons(sceneHTML)
    }

    // send user to dashboard if no POI in redux
    if (!poi) {
        return (<Redirect to='/dashboard' />)
    }

    return (
        <div>
            <Suspense fallback={<ProgressNoir color="secondary" />}>
                {poiCustomComponent}
                { inspector ? <Inspector 
                    src={`${inspector.path}/${inspector.file}`} 
                    placeholderSrc={ inspector.placeholder ? `${inspector.path}/${inspector.placeholder}` : undefined }
                /> : null }
                {/* alt media gets replaced */}
                { altMedia || media ? <MediaShow content={altMedia || media} /> : null }
                {/* alt content gets appended */}
                {poi.content ? <Content data={poi.content} /> : null}
                {altContent ? <Content data={altContent} hideBreadcrumbs={true} /> : null}
            </Suspense>
            <div className={classes.buttonsContainer}>
                {sceneButtons}
            </div>
        </div>
    )
}

export default POI
