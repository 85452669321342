import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getScene } from '../../reducers/sceneSlice'
import { setPuzzleStatus } from '../../../utils'
import { MediaImage, MediaVideo } from '../../Media'
import Content from '../../Content'
import Breadcrumbs from '../../Breadcrumbs'
import { ButtonNoir, InputNoir } from '../../library'
import { SimpleGuess } from '../../puzzles'

const BikeChain = () => {
    return (
        <div className="component">
            <SimpleGuess 
                id={'bike-chain'} 
                pathId={'inside-trunk'}
                solutions={['301']}
                successMsg="You've unlocked the drawer"
            />
        </div>
    )
}

export default BikeChain