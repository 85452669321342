import React, { useEffect, useState } from 'react'

import { getAllUserItems } from '../../utils'

const Items = () => {
    const [ items, setItems ] = useState<any[]>()

    const fetchItems = async () => {
        const items = await getAllUserItems({ asObject: true }) || []
        setItems(items)
    }

    useEffect(() => {
        fetchItems()
    }, [])
    
    const listOfItemsHTML = items?.length ? items.map(item => <div key={item.id}>{item.name}</div>) : null

    return (
        <div className="component">
            <h3>Items</h3>
            { items?.length ? listOfItemsHTML : `You've got nothing.`}
        </div>
    )
}

export default Items