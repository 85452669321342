import firebase from "firebase/app"
import 'firebase/firestore';
import "firebase/auth"

const app = firebase.initializeApp({
    apiKey: "AIzaSyCAlOcnZOLZKltVEL9DfL4zv8K8fO17F6M",
    authDomain: "capitol-noir.firebase.com",
    databaseURL: "https://capitol-noir-default-rtdb.firebaseio.com",
    projectId: "capitol-noir",
    storageBucket: "capitol-noir.appspot.com",
    messagingSenderId: "889411399909",
    appId: "1:889411399909:web:da7f63f8b9a871d672240c",
    measurementId: "G-B2EDFR63LY"
})

if (!firebase.apps.length) {
    firebase.initializeApp({});
} else {
    firebase.app(); // if already initialized, use that one
}

const db = app.firestore();

export const auth = app.auth()

export default app

export { db }

// Emulator
// https://firebase.google.com/docs/emulator-suite/connect_firestore

// export const auth = firebase.auth();
// auth.useEmulator("http://localhost:9099");

// import { getAuth, connectAuthEmulator } from "firebase/auth";

// export const auth = getAuth();
// connectAuthEmulator(auth, "http://localhost:9099");