import React from 'react'
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx'

import { ProgressNoir } from '../library'

interface buttonNoirType {
    name?: string,
    variant?: 'contained' | 'contained' | 'outlined',
    loading?: boolean,
    // All other props
    [x:string]: any,
}

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            borderRadius: 2,
            display: 'block',
            minHeight: 65,
            width: '95%',
            margin: '10px auto',
            fontSize: '1.3em'
        },
        loadingContainer: {
            position: 'relative',
        },
        loadingOverlay: {
            position: 'absolute',
            left: '50%',
            top: '10%',
        },
        loadingBackground: {
            opacity: .2,
        }
    }
})

const ButtonNoir = ({ name = '', variant = 'contained', loading, ...props }: buttonNoirType) => {
    const { classes } = useStyles()

    const loadingContent = () => {
        return (
            <div className={classes.loadingContainer}>
                <div className={classes.loadingOverlay}><ProgressNoir size="20px" color="inherit" /></div>
                <div className={classes.loadingBackground}>{props.children}</div>
            </div>
        )
    }

    return (
        <Button 
            variant={variant}
            className={clsx(classes.root, props?.className)} 
            disabled={loading}
            {...props}
        >
            { loading ? loadingContent() : props.children }
        </Button>
    )
}

export default ButtonNoir