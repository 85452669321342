import React, { useEffect, useState, CSSProperties } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from 'tss-react/mui';
import { EnumNumberMember } from '@babel/types'
import clsx from 'clsx'

import { getMyLocation, setMyLocation, setMap } from '../reducers/myLocationSlice'
import { getChapter } from '../reducers/caseSlice'
import { setScene } from '../reducers/sceneSlice'
import { setPOI } from '../reducers/poiSlice'
import { POIButton } from '../POI'
import { locationType } from '../types/location'
import { caseType } from '../types/case'
import { globalType } from '../types/data'
import { poiType, poiArrayType } from '../types/poi'
import { getAllChapterPOI, getPOIReqComplete, getCaseSubFolderFile, getCaseDataFile, isItemInPath  } from '../../utils'
import { Chapter } from '../Chapter'
import { flexbox } from '@mui/system';
import ProgressiveImg from '../ProgressiveImg'

// import a list of all locations

// TODO this should be in the  app state
// import { case001 } from '../../data/poi'

const debug = false
const defaultHaloPadding = 100

interface coordsType { 
    lat: number,
    long: number
}

interface mapCoordsType {
    north: number,
    south: number,
    east: number,
    west: number
}

interface mapImgType {
    height: Number, 
    width: number, 
    landscape: boolean,
    cssHeight: string,
    cssWidth: string,
}

const poiStyles = {
    backgroundColor: 'rgba(255, 0, 0, .3)',
    height: 12,
    width: 12,
    borderRadius: '12px',
    position: 'absolute',
    textAlign: 'center',
    fontSize: '8px',
    lineHeight: '11px',
}

const poiButtonStyles = {
    display: 'block',
    width: '100%',
    padding: '8px',
    margin: '5px 0'
}

const poiUnvisitedButtonStyles = {
    ...poiButtonStyles,
    backgroundColor: '#793737'
}

const poiGlobalButtonStyles = {
    ...poiButtonStyles,
    backgroundColor: '#288a63'
}

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            marginBottom: 25,
        },
        mapContainer: {
            display: 'flex',
        },
        map: {
            backgroundPostion: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'transparent',
            height: 373,
            backgroundSize: 'contain',
            position: 'relative',
            overflow: 'hidden',
        },
        myPos: {
            height: 10,
            width: 10,
            borderRadius: 10,
            backgroundColor: 'rgba(0,0,255,.5)',
            position: 'absolute',
            transition: 'left 1s, bottom 1s',
            pointerEvents: 'none',
        },
        myPosHalo: {
            backgroundColor: 'rgba(0,0,255,.2)',
            position: 'absolute',
            transition: 'left 1s, bottom 1s',
            pointerEvents: 'none',
        },
        buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    }
})

const Map = () => {
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const [caseData, setCaseData] = useState<any>({})
    const [caseMaps, setCaseMaps] = useState<any>({})
    const myLocation = useSelector(getMyLocation)
    const activeChapter = useSelector(getChapter)
    const [ checkChapterStatus, setCheckChapterStatus ] = useState(true)
    const [ debugMaps, setDebugMaps ] = useState<any>([])
    const [ mapDimensions, setMapDimensions ] = useState<mapImgType | undefined>()

    const [ haloStylePadding, setHaloStylePadding ] = useState(defaultHaloPadding)

    const [ currentLoc, setCurrentLoc ] = useState<locationType | undefined>(caseMaps.main)
    const [ myClickLocation, setMyClickLocation ] = useState<coordsType | undefined>()

    const [ poiItemsToDisplay, setPOIItemsToDisplay ] = useState<JSX.Element[]>([])
    const [ poiButtons, setPOIButtons ] = useState<JSX.Element[]>([])

    // const [ myCoords, setMyCoords ] = useState<coordsType | undefined>(undefined)
    const [ myLongPos, setMyLongPos ] = useState(0)
    const [ myLatPos, setMyLatPos ] = useState(0)

    const mapNorth = currentLoc && currentLoc.coords ? currentLoc.coords.north : 0
    const mapSouth = currentLoc && currentLoc.coords ? currentLoc.coords.south : 0
    const mapEast = currentLoc && currentLoc.coords ? currentLoc.coords.east : 0
    const mapWest = currentLoc && currentLoc.coords ? currentLoc.coords.west : 0

    const mapFGZIndex = 10
    const mapBGZIndex = 0

    useEffect(() => {
        setCurrentLoc(undefined)
        setCheckChapterStatus(false)
        // reset redux (todo: prob make this a global func)
        dispatch(setScene(null))
        dispatch(setPOI(null))
        getSettings()
    }, [])

    useEffect(() => {
        // call all functions necessary to build map/user/poi
        let myCoords
        
        if (myLocation.loading === true) {
            return
        }

        if (myLocation.source === 'click' && myClickLocation) {
            // myCoords = undefined
            // TODO this should be set to click
            myCoords = {
                lat: myClickLocation.lat,
                long: myClickLocation.long
            }
        } else {
            // source must be gelocation
            myCoords = {
                lat: myLocation.lat,
                long: myLocation.long
            }
            setMyClickLocation(undefined)
        } 

        getMap(myCoords)
        getMyPosition(myCoords)
        getPOI(myCoords)
    }, [currentLoc, myLocation, myClickLocation, caseMaps])

    useEffect(() => {
        getMapImgDimensions()
    }, [currentLoc, myLocation, myClickLocation, caseMaps])

    const getSettings = async () => {
        // get settings, case data & case maps
        const caseDataFile = await getCaseDataFile()
        setCaseData(caseDataFile.default)
        const caseMapsFile = await getCaseSubFolderFile('maps')
        setCaseMaps(caseMapsFile.default)
    }

    const getMap = (myCoords: coordsType) => {
        if (!caseMaps || !caseMaps.main) {
            return
        }

        let locationMap

        // determine map to use
        const maps = [caseMaps.main, ...caseMaps.details]

        // set to main, get settings, then override if neccesaary
        setCurrentLoc(caseMaps.main) 
        locationMap = caseMaps.main
        const { haloPadding, haloStylePadding, buffer } = locationMap
        setHaloStylePadding(haloStylePadding)

        // loop thru all locations and see if user is in one
        caseMaps.details.forEach((locMap: any) => {
            // add to debug array, if it doesnt already exist
            const mapContainer = document.getElementById('map')
            if (debug && mapContainer && !debugMaps.find((map: any) => map.name === locMap.name)) {
                // TODO: not sure if this is working correctly (part of debug feature)
                // get lat/long equivalants
                const debugTopLeftXLoc = getLatLongToPixel({ long: true }, (locMap.coords.west - buffer)) || 0
                const debugTopLeftYLoc = getLatLongToPixel({ lat: true }, (locMap.coords.north + buffer)) || 0
                const debugBottomRightXLoc = getLatLongToPixel({ long: true }, (locMap.coords.east + buffer)) || 0
                const debugBottomRightYLoc = getLatLongToPixel({ lat: true }, (locMap.coords.south - buffer)) || 0
                // debug maps are a topleft and bottomright points
                setDebugMaps([
                    ...debugMaps,
                    {
                        name: locMap.name,
                        x: debugTopLeftXLoc,
                        y: debugTopLeftYLoc,
                    },
                    {
                        name: `${locMap.name}-bottom-right`,
                        x: debugBottomRightXLoc,
                        y: debugBottomRightYLoc,

                    }
                ])
            }
            if (myCoords) {
                if ( 
                    (
                        myCoords.long > (locMap.coords.west - buffer) &&
                        myCoords.long < (locMap.coords.east + buffer)
                    ) &&
                    (
                        myCoords.lat > (locMap.coords.south - buffer) && 
                        myCoords.lat < (locMap.coords.north + buffer)
                    )
                ) {
                    locationMap = locMap
                    setCurrentLoc(locMap)
                    setHaloStylePadding(locationMap.haloStylePadding)
                }
            }
        })
        dispatch(setMap(locationMap))
        // TODO: might need to dispatch/override haloPadding or buffer here?
    }
    
    const getMyPosition = (myCoords: coordsType) => {
        // calc my position on map

        if (!myCoords) {
            return
        }

        // TODO POI needs this too
        const long100 = (mapEast - mapWest)  * 10000
        const lat100 = (mapNorth - mapSouth)  * 10000

        // long
        const myLong100 = (myCoords.long - mapWest) * 10000
        const myLongPosCalc = (myLong100 / long100) * 100
        setMyLongPos(myLongPosCalc)
        // lat
        const myLat100 = (myCoords.lat - mapSouth) * 10000
        const myLatPosCalc = (myLat100 / lat100) * 100
        setMyLatPos(myLatPosCalc)
    }

    const getPOIPosition = ( (poi: any) => {
        // calc poi position on map

        // TODO getMyPosition needs this too
        const long100 = (mapEast - mapWest)  * 10000
        const lat100 = (mapNorth - mapSouth)  * 10000

        // TODO: upgrade this to handle multiple locations for single POI
        const poiLong100 = (poi.coordinates[0].long - mapWest) * 10000
        const poiLongPosCalc = (poiLong100 / long100) * 100
        const poiLat100 = (poi.coordinates[0].lat - mapSouth) * 10000
        const poiLatPosCalc = (poiLat100 / lat100) * 100
        return {
            long: poiLongPosCalc,
            lat: poiLatPosCalc
        }
    })

    const getPOI = async (myCoords: coordsType) => {
        // place POI on map
        if (!currentLoc || !myCoords.long) {
            return
        }

        // get all poi up to current chapter
        const allChapterPOI:poiArrayType = await getAllChapterPOI({
            targetChapter: activeChapter,
            includePrevious: true,
            flattened: true,
            mergeImports: true
        })     

        // get all global poi at location
        const allGlobalPOI = caseData && caseData.globalPOI ? caseData.globalPOI : []
        const globalPOIAtLocation: any[] = []
        Object.keys(allGlobalPOI).forEach( (key: string) => { 
            const poi = allGlobalPOI[key]
            poi.locations.forEach( (loc: any, key: any) => {
                if (loc.name === currentLoc.name) {
                    const poiId = loc?.id || key
                    globalPOIAtLocation.push({
                        ...poi,
                        id: `${poi.id}-${poiId}`,
                        globalId: poi.id,
                        location: loc.name,
                        coordinates: loc.coordinates
                    })
                }
            })
        } )

        // get poi at location, that fufill requirements
        let poiItems:poiArrayType = allChapterPOI
            .filter( (poi: { location: string }) => poi.location === currentLoc.name)

        // get poi that have requirements complete
        poiItems = await getPOIReqComplete(poiItems)
        
        // get poi within range
        const poiIsInRange = (coords: { long: string; lat: string }) => {
            const { haloPadding } = currentLoc
            const roundCoord = (coord:any) => {
                if(typeof coord === 'string') {
                    coord = parseFloat(coord)
                }
                return parseFloat(coord.toFixed(7))
            }
            if ((
                    roundCoord(coords.long) > (roundCoord(myCoords.long) - haloPadding) &&
                    roundCoord(coords.long) < (roundCoord(myCoords.long) + haloPadding)
                ) &&
                (
                    roundCoord(coords.lat) > (roundCoord(myCoords.lat) - haloPadding) && 
                    roundCoord(coords.lat) < (roundCoord(myCoords.lat) + haloPadding)
                )) {
                return true
            } else {
                return false
            }
        }
        
        // poi is within range
        const poiItemsToDisplay:poiArrayType = [ ...poiItems, ...globalPOIAtLocation]
            .filter( (p: any) => p.visibility !== 'never')
            .filter( (p: any) => {
                if (!p.coordinates) {
                    console.error(`${p.id}: missing coordinates in json.`)
                }
                return (p.coordinates && poiIsInRange(p.coordinates[0])) || p.visibility === 'always'
            }).map( (p: any) => {
                if (poiIsInRange(p.coordinates[0])) {
                    return {
                        ...p,
                        visibility: 'in-range'
                    }
                } else {
                    return p
                }
            })

        // if items in range...
        if (poiItemsToDisplay.length >= 1) {
            // add dots to map
            const poiItemsHTML = poiItemsToDisplay.map((p: any) =>{
                const poiLoc = getPOIPosition(p)
                const pStyles = {
                    ...poiStyles,
                    left: `${poiLoc.long}%`,
                    bottom: `${poiLoc.lat}%`,
                    pointerEvents: 'none',
                    backgroundColor: p.visibility === 'always' ? 'rgba(0, 150, 100, .6)' : 'rgba(255, 0, 0, .3)',
                    zIndex: mapFGZIndex,
                } as CSSProperties
               return  <div key={`${p.id} ${p.name}`} id={`poi-${p.id}`} style={pStyles}></div>
            })            
            setPOIItemsToDisplay(poiItemsHTML)
            // add buttons to ui
            const poiItemsToDisplayWithVisted = await Promise.all(poiItemsToDisplay
                    .map( async (p: any) => {
                        return {
                            ...p,
                            visited: await isItemInPath(p.id) ? true : false
                        }
                    }
                ))
            // poiItemsToDisplayWithVisted.sort((a, b) => { return a.visited - b.visited; } )
            
            const poiButtonItemsHTML = poiItemsToDisplayWithVisted
                .filter( (p: any) => p.visibility === 'in-range')
                .map( (p: any) => 
                <POIButton 
                    key={`${p.id} ${p.name}`} 
                    poi={p} 
                    style={p.visited ? poiButtonStyles : p.global ? poiGlobalButtonStyles : poiUnvisitedButtonStyles}>{p.name}</POIButton>
            )
            setPOIButtons(poiButtonItemsHTML)
        } else {
            setPOIItemsToDisplay([])
            setPOIButtons([])
        }

        // if always show items...
        
    }

    const getClickPositon = (event: any) => {
        // get the position user clicked on map        
        const mapContainer = document.getElementById('map')

        if (myLocation.source === 'geolocation' || !mapContainer) {
            return
        }

        const xOffset = event.target.offsetLeft + 7
        const yOffset = event.target.offsetTop - 2
        
        var xPosition = event.clientX;
        var yPosition = event.clientY;

        // get long equivalant
        const xPercent = (xPosition - xOffset) / mapContainer.clientWidth
        const mapXDiff = mapEast - mapWest
        const mapXPercent = xPercent * mapXDiff
        const mapXLoc = mapXPercent + mapWest

        // get last equivalant
        const yPercent = (mapContainer.clientHeight - yPosition + yOffset) / mapContainer.clientHeight
        const mapYDiff = mapNorth - mapSouth
        const mapYPercent = yPercent * mapYDiff
        const mapYLoc = mapYPercent + mapSouth

        setMyClickLocation({
            lat: mapYLoc, 
            long: mapXLoc
        })
    }

    const getLatLongToPixel = (type: {lat?: boolean, long?: boolean}, coord: any) => {
        // TODO: not sure if this is working correctly (part of debug feature)
        const mapContainer = document.getElementById('map')
        if (!mapContainer) { 
            return null
        }
        let pixel = 0
        if (type.lat) {
            // get lat equivalant
            const yPercent = coord / mapContainer.clientHeight
            const mapYDiff = mapNorth - mapSouth
            const mapYPercent = yPercent * mapYDiff
            pixel = mapYPercent //mapYPercent + mapSouth
        } else {
            // get long equivalant
            const xPercent = coord / mapContainer.clientWidth
            const mapXDiff = mapEast - mapWest
            const mapXPercent = xPercent * mapXDiff
            pixel = xPercent //mapXPercent + mapWest
        }
        return pixel
    }

    const debugMapPoints = () => {
        console.log('debugMaps',debugMaps);
        const maps = debugMaps.map((map: any) => {
            // TODO: not sure if this is working correctly (part of debug feature)
            console.log('map',map);
            let divStyles = {}
            if (map.name.includes('bottom-right')) {
                // bottom right
                divStyles = {
                    position: 'absolute',
                    top: `${map.y*10000}%`,
                    left: `${map.x*-100}%`,
                    height: '5px',
                    width: '5px',
                    pointerEvents: 'none',
                    backgroundColor: 'rgb(0 0 255 / .5)',
                    zIndex: mapFGZIndex,
                 } as React.CSSProperties;
            } else {
                // top left
                divStyles = {
                    position: 'absolute',
                    top: `${map.y*10000}%`,
                    left: `${map.x*-100}%`,
                    height: '5px',
                    width: '5px',
                    pointerEvents: 'none',
                    backgroundColor: 'rgb(0 255 0 / .5)',
                    zIndex: mapFGZIndex,
                } as React.CSSProperties;
            }
            return <div key="map.name" style={divStyles}/>
        })
        return maps
    }

    const getMapImgDimensions = () => {
        const img = new Image();
        img.onload = () => {
            const landscape = img.width >= img.height ? true : false
            let cssHeight = 'auto'
            let cssWidth = '100vw'
            if (!landscape && img.height > window.innerHeight) {
                cssWidth = 'auto'
            }
            setMapDimensions({
                height: img.height,
                width: img.width,
                cssHeight: cssHeight,
                cssWidth: cssWidth, 
                landscape
            })
        }
        img.src = currentLoc?.placeholder ? currentLoc.placeholder : currentLoc?.map ? currentLoc.map : ''
    }

    // styles
    const mapStyle = {
        margin: 'auto',
        height: 'auto',
        width: 'auto',
        display: 'inline-flex',
    } as CSSProperties

    const mapImgStyle = {
        height: mapDimensions?.cssHeight || 'auto',
        width: mapDimensions?.cssWidth || 'auto',
        maxWidth: '100vw',
        maxHeight: '95vh',
        pointerEvents: 'none' as 'none',
        zIndex: mapBGZIndex,
    }

    const myPosStyle = {
        left: `${myLongPos}%`,
        bottom: `${myLatPos}%`,
        zIndex: mapFGZIndex,
    } as CSSProperties

    const myPosHaloStyle = {
        height: 10 + haloStylePadding,
        width: 10 + haloStylePadding,
        borderRadius: 10 + haloStylePadding,
        left: `${myLongPos}%`,
        bottom: `${myLatPos}%`,
        transform: `translate(-${haloStylePadding/2}px, ${haloStylePadding/2}px)`,
        zIndex: mapFGZIndex,
    } as CSSProperties

    return (
        <div className={clsx('component', classes.root)}>
            {checkChapterStatus ? <Chapter /> : null }
            <div className={classes.mapContainer}>
                <div id="map" className={classes.map} style={mapStyle} onClick={(event) => getClickPositon(event)}>
                    <div className={classes.myPosHalo} style={myPosHaloStyle}></div>
                    <div className={classes.myPos} style={myPosStyle}></div>
                    {poiItemsToDisplay}
                    { debugMaps.length >= 1 ? debugMapPoints() : null }
                    <ProgressiveImg 
                        placeholderSrc={currentLoc?.placeholder ? currentLoc.placeholder : null} 
                        src={currentLoc ? currentLoc.map : ''} 
                        style={mapImgStyle} 
                        alt={currentLoc?.name}
                        defaultPlaceholderSmall={true}
                    />
                </div>
            </div>
            {currentLoc?.displayName || poiButtons.length ? <div>{ currentLoc ? currentLoc.name : '' }</div> : null}
            <div className={classes.buttonsContainer}>
                {poiButtons}
            </div>
        </div>
    )
}

export default Map