import React, { useEffect, useState } from 'react'
import { useAuth } from './contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import {  useSelector } from 'react-redux'

import { getDebugActive } from '../reducers/debugSlice'
import Items from '../Items'
import { getRemainingPOIAndScenes } from '../../utils'
import { ButtonNoir } from '../library'

export default function Profile() {
    const debugActive = useSelector(getDebugActive)
    const [error, setError] = useState('')
    const { currentUser, resetCaseSettings } = useAuth()
    const history = useHistory()
    const [ loading, setLoading ] = useState(false)
    const [ remainingPOIAndScenes, setRemainingPOIAndScenes ] = useState([])

    useEffect(() => {
        getRemainingPOI()
    }, [])

    const getRemainingPOI = async () => {
        const remainingPOIAndScenes = await getRemainingPOIAndScenes()
        setRemainingPOIAndScenes(remainingPOIAndScenes.map(item => item.name))
    }

    async function resetCase() {
        setLoading(true)
        setError('')

        if (!window.confirm('Are you sure you wish to reset the case? You will lose all data.')) {
            return
        }

        try {
            const caseReset = await resetCaseSettings({ resetLogout: false })
            if (caseReset) {
                history.push('/welcome')
            } else {
                setError('Error: Unable to reset case.')    
            }
        } catch(e) {
            console.error('Error resetting case:',e);
            setError('Error: Unable to reset case.')
        }
    }

    const debugItems = () => {
        return (
            <>
                {error && <div>{error}</div>}
                <div>
                    <strong>Display Name:</strong> {currentUser?.displayName ? currentUser.displayName : ''}<br/>
                    <strong>UID:</strong> {currentUser?.uid ? currentUser.uid : ''}
                </div>
                {/* photoUrl = user.photoURL; */}
                <div><strong>Email:</strong> {currentUser && currentUser.email ? currentUser.email : null}</div>
                <Link to='/update-profile'>Update Profile</Link>
                <button onClick={resetCase}>Log Out</button>
            </>
        )
    }

    return (
        <>
            <div>
                <h2>Who are we?</h2>

                <Items />

                <h3>Remainig Required:</h3>
                {remainingPOIAndScenes.map((item, key) => <div key={key}>{item}</div>)}

                <br/><br/><br/><hr/>
                <ButtonNoir onClick={resetCase} loading={loading}>Reset</ButtonNoir>

                { debugActive ? debugItems() : null }
                v0.0.12
            </div>
        </>
    )
}
