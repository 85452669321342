import Profile from './Profile'
import Login from './Login'
import PrivateRoute from './PrivateRoute'
import ForgotPassword from './ForgotPassword'
import UpdateProfile from './UpdateProfile'
import Signup from './Signup'

export {
    Profile,
    Login,
    PrivateRoute,
    ForgotPassword,
    UpdateProfile,
    Signup,
}