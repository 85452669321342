import { caseDataType } from '../components/types/case'
import  { getCaseData, getUserActiveCase, updateUserActiveCase, getRemainingReqPOI, getRemainingReqScenes } from '../utils'
import settings from '../settings.json'

const allPOIScenes: any = []

export const getAllPOIScenes = (object: any, reset: boolean = true) => {
    if (reset) {
        allPOIScenes.length = 0
    }
    // step thru and return all scenes in chapter
    var level = 1;
    for(var key in object) {
        if (isNaN(+key) && key !== 'poi' && key !== 'scenes') {
            continue
        }
        if(typeof object[key] == 'object') {
            if (object[key].id) {
                allPOIScenes.push({
                    id: object[key].id ? object[key].id : 'no id',
                    required: object[key]?.required === false ? false : true,
                    chapter: object[key].chapter ? true : false // pass this to remove chapter node later in code
                })
            }
            level = Math.max(POIScenes, level);
            var POIScenes = getAllPOIScenes(object[key], false) + 1;
        }
    }
    return allPOIScenes;
}

export const getRemainingPOIAndScenes = async () => {
    const remainingReqPOI = await getRemainingReqPOI()    
    const remainingReqScenes = await getRemainingReqScenes()
    return [...remainingReqPOI, ...remainingReqScenes]
}

export const getChapterStatus = async (currentChapter: any) => {
    if (settings.debug) {
        console.log('getChapterStatus() this is being called multiple times on Dashboard. can be made more efficient.');
    }
    
    let status = {
        caseComplete: false,
        chapterComplete: false,
        activeChapter: currentChapter,
        dirty: null
    }
    
    allPOIScenes.length = 0
    if (settings.debug) {
        console.log('TODO: await get poi and scene. can this be combined?');
    }

    // check on POI and scenes
    const caseData: caseDataType | any = await getCaseData()
    const activeCase = await getUserActiveCase()
    const remainingPOIAndScenes = await getRemainingPOIAndScenes()

    if (settings.debug) {
        console.log('currentChapter',currentChapter);
    } else if (settings.contentEditing) {
        // console.log('------------------------------');
        // const unvisitedPOIScenes = await getUnvisitedPOIAndScenes()
        // console.log('unvisitedPOIScenes:',unvisitedPOIScenes);
        console.log('remaining Req POI & Scenes',remainingPOIAndScenes.map(item => item.id));
    }
    
    if (remainingPOIAndScenes.length < 1) {
        const nextChapter = currentChapter + 1
        if (nextChapter > caseData.chapters.length) {
            if(settings.debug) {
                console.log('**** getChapterStatus: Case Complete ****')
            }
            updateUserActiveCase({
                caseComplete: true
            })
            status = {
                caseComplete: true,
                chapterComplete: true,
                activeChapter: -1,
                dirty: null
            }
        } else {
            if(settings.debug) {
                console.log('**** getChapterStatus: Chapter Complete *****');
            }
            // update DB
            updateUserActiveCase({
                chapter: nextChapter,
                dirty: false
            })
            status = {
                caseComplete: false,
                chapterComplete: true,
                activeChapter: nextChapter,
                dirty: null
            }
        }
    }

    status.dirty = activeCase.dirty

    return status
}
export const getChapter = async () => {
    // return the stored chapter
    const activeCase = await getUserActiveCase()
    if (!activeCase || !activeCase.chapter) {
        return 1
    }
    return activeCase.chapter
}

export const getChapterString = (chapter: number) => {
    if (chapter < 9) {
        return `0${chapter}`
    } else {
        return chapter.toString()
    }
}

export const getChapterByPathId = async  (id: string) => {
    if (!id.includes('chapter-')) {
        return false
    }
    const chapterId = id.split('-')[1]
    return chapterId
}